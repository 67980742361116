import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { storeItemData } from "../StoreItemData";
import { AppUpdate } from "../objects/StoreItemUpdate";
import { StoreUpdateItems } from "./StoreUpdateItems";
import { Icon } from "../Icons";

export type StoreUpdateItemProps = {
	/**
	 * Contains data needed to build up the object (apps, blocks, extensions)
	 */
	data: AppUpdate;

	/**
	 * The url of the previous page
	 */
	prevPage: string;

	/**
	 * Trigger update
	 */
	update: (app: AppUpdate) => void;

	/**
	 * State of the update
	 */
	updateState: "eligible" | "waiting" | "installing";

	/**
	 * Key
	 */
	key?: string;
};

export const StoreUpdateItem = (props: StoreUpdateItemProps) => {
	const [details, setDetails] = useState({
		tags: [""],
		icon: "",
	});
	const [updateState, setUpdateState] = useState<"eligible" | "waiting" | "installing">("eligible");

	// Monitor the app
	useEffect(() => {
		setUpdateState(props.updateState);
	}, [props.updateState]);

	/**
	 * Install the app update
	 */
	const updateApp = (e: any) => {
		e.preventDefault();
		props.update(props.data);
	}

	useEffect(() => {
		// Get store item data from json
		storeItemData.forEach((storeItem) => {
			const items = storeItem.items[0];

			if (storeItem.id === props.data.id) {
				setDetails((prevState) => ({
					...prevState,
					tags: items.tags,
					icon: items.icon,
				}));
			}
		});
	}, [props.data.id]);

	const getButton = () => {
		switch (updateState) {
			case "eligible":
				return <a onClick={updateApp} className="button button-apps button-icon button-small">
					Installeer update
					<i className="icon icon-download"></i>
				</a>;

			case "waiting":
				return <a onClick={(e) => { e.preventDefault(); }} className="button button-apps button-small">
					<i className="icon icon-spinner" /> Pending...
				</a>;

			case "installing":
				return <a onClick={(e) => { e.preventDefault(); }} className="button button-apps button-small">
					<i className="icon icon-spinner" /> Installing...
				</a>;
		}
	}

	return <div className="card">
		<div className="card-top">
			<div className="image">
				<Icon details={details} data={props.data} />
			</div>
			<div className="card-content">
				<h3>{props.data.name}</h3>
				<p>{details.tags.join(", ")}</p>
			</div>
		</div>
		<div className="card-bottom">
			{getButton()}
		</div>
	</div>
}
