import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { InstallModal } from "./install/InstallModal";
import { useEffect, useState, useContext } from "react";
import { LoadingIndicatorStoreItems } from "./loading-indicator/LoadingIndicatorStoreItems";
import { LoadingType } from "./types/LoadingTypes";
import { storeItemData } from "./StoreItemData";
import { EnvironmentContext } from "./EnvironmentContext";
import Slider from "react-slick";
import { useAuth } from "react-oidc-context";
import { StoreItem } from "./objects/StoreItem";
import { Helmet } from "react-helmet";
import { BackButton } from "./BackButton";
import { DetailPageCard } from "./DetailPageCard";
import { useStoreBackend } from "./hooks/useStoreBackend";
import { GroupedStoreItem } from "./objects/GroupedStoreItem";

export const DetailPage = () => {
	const auth = useAuth();
	const [loading, setLoading] = useState<boolean>(true);
	const { t } = useTranslation();
	const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
	const [storeItem, setStoreItem] = useState<StoreItem>();
	const [storeItems, setStoreItems] = useState<GroupedStoreItem[]>([]);
	const [show, setShow] = useState<boolean>(false);
	const [details, setDetails] = useState({
		id: "",
		subtitle: "",
		images: [""],
		description: "",
		icon: "",
		tag: "",
	});
	const [photoIndex, setPhotoIndex] = useState<number>(0);
	const [open, setOpen] = useState<boolean>(false);
	const sb = useStoreBackend();

	const { environment } = useContext(EnvironmentContext);

	/**
	 * Get relevant storeitem id and content type (app, block, extention)
	 */
	const location = useLocation() as any;
	const prevPage = location.state?.prevPage;
	const itemId = location.state?.itemId;
	const { name } = useParams<{ name: string }>();

	// Get correct store item details from json
	useEffect(() => {
		const storeItems = storeItemData.filter(i => i.name.toUpperCase() === name?.toUpperCase());
		if (storeItems.length === 1) {
			const storeItem = storeItems[0];
			setDetails((prevState) => ({
				...prevState,
				id: storeItem.id,
				images: storeItem.items[0].images,
				subtitle: storeItem.items[0].subtitle,
				description: storeItem.items[0].description,
				icon: storeItem.items[0].icon,
			}));
		} else if (itemId) {
			setDetails((prevState) => ({
				...prevState,
				id: itemId
			}));
		}
	}, [name]);

	// Get Relevent item details
	useEffect(() => {
		if (!details.id) return;
		loadApp();
	}, [details.id, environment, auth.isAuthenticated]);

	/**
	 * Load the app
	 */
	const loadApp = () => {
		setLoadingFailed(false);
		sb.getStoreDetails(details.id)
			.then((data) => {
				setStoreItem(data);
			})
			.catch((error) => {
				console.error("Error: ", error);
				setLoadingFailed(true);
			}).finally(() => {
				setLoading(false);
			});
	}

	/**
	 * Computes amount of slides to show
	 * @returns slides to show prop for slider
	 */
	const setSlidesToShow = (breakpoint: boolean) => {
		const imageAmount = details.images.length;
		if (breakpoint) {
			if (imageAmount > 1) return 2;
			else return imageAmount;
		} else {
			if (imageAmount > 2) return 3;
			else return imageAmount;
		}
	};

	// Slider setting
	const settings = {
		slidesToShow: setSlidesToShow(false),
		slidesToScroll: 1,
		prevArrow: (
			<button type="button" className="slider-button slider-button-prev" title="Vorige">
				<i className="icon icon-chevron-left"></i>
			</button>
		),
		nextArrow: (
			<button type="button" className="slider-button slider-button-next" title="Volgende">
				<i className="icon icon-chevron-right"></i>
			</button>
		),
		infinite: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: setSlidesToShow(true),
					slidesToScroll: 1,
					infinite: false,
					dots: false,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: false,
					dots: false,
				},
			},
		],
	};

	/**
	 * Opens install modal
	 */
	const handleClick = () => {
		setShow(true);
	};

	/**
	 * Process the successfull installation state, so the UI is updated with the final button state
	 */
	const processInstallState = () => {
		
		// Reload the full app
		// TODO: Only process the final app state and update the button, instead of the whole app UI
		loadApp();
	}

	/**
	 * Desides what to return to the page
	 * @returns Page content
	 */
	const getContent = () => {
		if (loading) {
			return (
				<LoadingIndicatorStoreItems indicator={LoadingType.LOADING} text={t("LOADING", { ns: "common" })} />
			);
		} else if (!storeItem || loadingFailed) {
			return (
				<LoadingIndicatorStoreItems
					indicator={LoadingType.ERROR}
					text={t("LOADING_FAILED", { ns: "common" })}
				/>
			);
		} else {
			return (
				<>
					<Helmet>
						<title>{storeItem.name} - GeoApps Store</title>
						<meta name="og:site_name" content="GeoApps Store" />
						<meta name="og:title" content={`${storeItem.name} - GeoApps Store`} />
						<meta name="og:description" content={details.subtitle} />
						<meta name="og:url" content={window.location.href} />
						<meta name="og:image" content={`${window.location.origin}/${details.images[0]?.replace("./", "")}`} />
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:title" content={`${storeItem.name} - GeoApps Store`} />
						<meta name="twitter:description" content={details.subtitle} />
						<meta name="twitter:image" content={`${window.location.origin}/${details.images[0]?.replace("./", "")}`} />
						<meta name="twitter:image:alt" content={storeItem.name} />
					</Helmet>
					<div className="container">
						<div className="column-left">
							<DetailPageCard onClick={handleClick} details={details} storeItem={storeItem} />
						</div>
						<div className="column-right">
							<div className="card">
								<Slider className="screenshots" {...settings}>
									{details.images.map((image, idx) => (
										<img key={idx} src={image} alt={image} onClick={() => { setPhotoIndex(idx); setOpen(true) }} />
									))}
								</Slider>
								{/* {open && (<Lightbox
									mainSrc={details.images[photoIndex]}
									nextSrc={details.images[(photoIndex + 1) % details.images.length]}
									prevSrc={details.images[(photoIndex + details.images.length - 1) % details.images.length]}
									onCloseRequest={() => setOpen(false)}
									onMovePrevRequest={() => setPhotoIndex((photoIndex + details.images.length - 1) % details.images.length)}
									onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % details.images.length)}
								/>
								)} */}
								<div>
									{details.subtitle && <h2>{details.subtitle}</h2>}
									<div dangerouslySetInnerHTML={{ __html: details.description }} />
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	};

	return (
		<div className={`detail style-${storeItem?.manifestType.toLocaleLowerCase()}s`}>
			<div className="background"></div>
			<div className="overlay"></div>
			<div className="container">
				<BackButton to={prevPage ? prevPage : "/"} />
			</div>
			{getContent()}
			{show && storeItem && (
				<InstallModal
					storeItem={storeItem.name}
					setShow={setShow}
					id={storeItem.id}
					outdated={storeItem.outdated}
					installSuccessfull={processInstallState}
				/>
			)}
		</div>
	);
};
