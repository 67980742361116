import { useTranslation } from "react-i18next";
import { StoreItem } from "../objects/StoreItem";
import { useAuth } from "react-oidc-context";
import { getCost } from "../GetCost";

export type SimpleCardBottomProps = {
    /**
     * Contains data needed to build up the object (apps, blocks, extensions)
     */
    storeItem: StoreItem;
}

export const SimpleCardBottom = (props: SimpleCardBottomProps) => {
    const { t } = useTranslation();
    const auth = useAuth();

    /**
     * Check whether the store item needs an update, can be installed or is already installed.
     * @returns button with status of store item
     */
    const checkUpdate = () => {
        let button;

        switch (props.storeItem.outdated) {
            case true:
                button = <div className="tag status-update-available">{t("UPDATE_AVAILABLE")}</div>;
                break;
            case false:
                button = <div className="tag status-installed">{t("INSTALLED")}</div>;
                break;
            default:
                button = <div className="tag">{t(getCost(props.storeItem.visibility))}</div>;
        }

        return button;
    };

    return <>
        {auth.isAuthenticated ? <>{checkUpdate()}</> : <div className="tag">{t(getCost(props.storeItem.visibility))}</div>}
    </>
}
