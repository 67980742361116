/**
 * Determines the cost based on the visibility status.
 * @param visibility - The visibility status of the item.
 * @returns The cost associated with the visibility status.
 */
export const getCost = (visibility: string): string => {
    const costs: { [key: string]: string } = {
        "Public": "FREE",
        "OnRequest": "ON_REQUEST"
    };
    return costs[visibility] || visibility;
};
