export const storeItemData = [
    {
        "id": "5cf86949-60f2-41d9-a443-b183d5bc632a",
        "name": "Youtube",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/video.svg",
                "subtitle": "",
                "description": "<p>Met het YouTubeblock kunt u uw eigen of andermans YouTube video’s inladen in uw app. Naast het instellen van een YouTube video kunt u bijvoorbeeld ook een starttijd instellen, bediening wel of niet tonen of de fullscreen knop wel of niet verbergen.</p>",
                "images": [],
                "tags": [
                    "Video",
                    "Media",
                ],
            }
        ]
    },
    {
        "id": "153a9fb1-c2f8-4028-ae33-8443481ae705",
        "name": "Vimeo",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/video.svg",
                "subtitle": "",
                "description": "<p>Met het Vimeoblock kunt u uw eigen of andermans Vimeo video’s inladen in uw app. Naast het instellen van een Vimeo video kunt u bijvoorbeeld ook een starttijd instellen, bediening wel of niet tonen of automatisch afspelen instellen.<p>",
                "images": [],
                "tags": [
                    "Video",
                    "Media",
                ],
            }
        ]
    },
    {
        "id": "c5c9c899-7792-4d10-8d94-02286f98d8b3",
        "name": "Tekst",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/text.svg",
                "subtitle": "",
                "description": "<p>Met het Tekstblock kunt u uw eigen teksten inladen in uw app. Met het Tekstblock kunt u uw eigen tekst ook opmaken door bijvoorbeeld de tekst te kleuren, cursief te maken of door er hyperlinks aan toe te voegen.</p>",
                "images": [],
                "tags": [
                    "Content",
                    "TekstVerwerking",
                    "Informatie"
                ],
            }
        ]
    },
    {
        "id": "c94d8065-4b8e-4280-b98f-81030b3b8cf3",
        "name": "Tabel",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/table.svg",
                "subtitle": "",
                "description": "<p>Met de Tabelblock kunt u uw data weergeven in een tabel. Kies welke data u wilt weergeven in uw tabel en pas hier eventueel filters op toe. Stel bijvoorbeeld de kolomuitlijning in of bijvoorbeeld een voor- en/of achtervoegsel voor de kolom.</p>",
                "images": [],
                "tags": [
                    "Inzichten",
                    "Grafieken"
                ],
            }
        ]
    },
    {
        "id": "dc3c0c71-4048-423c-86a3-901a562b9fd2",
        "name": "Staafdiagram",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/bar.svg",
                "subtitle": "",
                "description": "<p>Met de Staafdiagramblock kunt u data weergeven in de vorm van een staafdiagram. Pas filters toe op uw data of schakel tussen een verticale of horizontale staafdiagram. Ook bent u in staat om de legenda aan te passen.</p>",
                "images": [],
                "tags": [
                    "Inzichten",
                    "Grafieken"
                ],
            }
        ]
    },
    {
        "id": "3c0443e0-f3bf-43f9-a7d6-c75d886539ad",
        "name": "PDOK Location Server",
        "manifestType": "Extension",
        "items": [
            {
                "icon": "./resources/images/logos/pdok-logo.svg",
                "description": "<p>Met de PDOK Locatieserver kunt u zoeken op actuele adressen (BAG), kadastrale percelen, wegenbestand en CBS wijk/buurt-gegevens in Nederland. Hiermee wordt het eenvoudig naar de gewenste locatie te navigeren in uw kaart.</p><p>Zoek met deze service op adresgegevens, perceelnummer, wegnummer en wijk en/of buurtnaam. Stel via GeoApps Manager eenvoudig welke services beschikbaar moeten zijn per kaart, zodat dit helemaal naar eigen wens ingericht wordt.</p>",
                "subtitle": "",
                "images": ["./resources/images/screenshots/extension/pdok-locatieservice.jpeg"],
                "tags": [
                    "Productiviteit",
                    "Open data"
                ],
            }
        ]
    },
    {
        "id": "c03ebdeb-b2e4-4ce5-8dbe-76503666964d",
        "name": "Nummer",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/number.svg",
                "subtitle": "",
                "description": "<p>Met het Nummerblock kunt u uw data visualiseren in de vorm van een enkel nummer. Ook bent u in staat om iconen of een voor- en/of achtervoegsel toe te voegen aan het block.</p>",
                "images": ["./resources/images/screenshots/blocks/nummers.jpeg"],
                "tags": [
                    "Inzichten",
                    "Grafieken"
                ],
            }
        ]
    },
    {
        "id": "f3d0b32a-d99f-4809-a7ef-50c61603c86b",
        "name": "MarXact",
        "manifestType": "Extension",
        "items": [
            {
                "icon": "./resources/images/logos/marXact-logo-groen.png",
                "subtitle": "",
                "description": "<p>Met GeoApps Fieldwork breiden we de tekenmodule van GeoApps uit met de ondersteuning van MarXact GPS-apparaten. Hierdoor is het nu mogelijk om als veldmedewerker objecten (zoals lijnobjecten of gebieden) in te winnen met hoge GPS-nauwkeurigheid. De ingewonnen objecten worden direct opgeslagen in het vooraf gedefinieerde GeoApps kaartproject. Deze functionaliteit is beschikbaar op aanvraag. Ondersteuning voor diverse merken GPS landmeetapparatuur is in ontwikkeling. Informeer naar de mogelijkheden.</p>",
                "images": ["./resources/images/screenshots/extension/fieldwork-marxact.jpg"],
                "tags": [
                    "GPS",
                    "Data inwinnen"
                ],
            }
        ]
    },
    {
        "id": "c3e31023-0bba-4c2d-aff4-f3ad2946ab09",
        "name": "MapTour",
        "manifestType": "App",
        "items": [
            {
                "icon": "./resources/images/maptour.svg",
                "subtitle": "Maak verhalende websites met kaarten, teksten, foto’s en illustraties",
                "description": "MapTour maakt het voor elke GeoApps-gebruiker mogelijk om in een handomdraai een aansprekende, verhalende website te maken. Maak bijvoorbeeld een online verhaal van uw geplande woningbouwplan of duurzaamheidsproject. Deel uw verhaal in logische, interactieve hoofdstukken op. Voeg interactieve kaarten toe en voorzie ze van een begrijpelijke uitleg. Gebruik teksten, foto’s, grafieken of illustraties. <br/> <h3>Geen ingewikkeld verhaal</h3> Het maken van een online verhaal met MapTour is eenvoudig, u hoeft hiervoor geen webdesigner te zijn. De beheeromgeving bevat de benodigde tools om in vijf eenvoudige stappen uw verhaal online te publiceren: <br/> <ul> <li>Stel gewenste huisstijl in (of kies eerder aangemaakte stijl)</li><li>Bepaal uw gewenste hoofdstukken en schermindeling</li><li>Voeg content toe, zoals teksten, (bestaande) kaartcomponenten, afbeeldingen en/of foto’s</li><li>Controleer het resultaat en pas eventueel nog wat aan</li><li>Publiceer uw verhaal en breng het onder de aandacht!</li> </ul>",
                "images": [
                    "./resources/images/screenshots/maptour/maptour_1_01.png",
                    "./resources/images/screenshots/maptour/maptour_1_02.png",
                    "./resources/images/screenshots/maptour/maptour_1_03.png",
                    "./resources/images/screenshots/maptour/maptour_1_05.png",
                    "./resources/images/screenshots/maptour/maptour_1_04.png",
                    "./resources/images/screenshots/maptour/maptour_2_02.png",
                    "./resources/images/screenshots/maptour/maptour_2_01.png",
                    "./resources/images/screenshots/maptour/maptour_2_03.png",
                    "./resources/images/screenshots/maptour/maptour_2_04.png",
                    "./resources/images/screenshots/maptour/maptour_2_05.png"
                ],
                "tags": [
                    "Fraaie presentaties maken"
                ],
            }
        ]
    },
    {
        "id": "18182cef-ae58-4a25-a4f3-deaae806b815",
        "name": "Widget",
        "manifestType": "App",
        "items": [
            {
                "icon": "./resources/images/widget.svg",
                "subtitle": "Embed eenvoudig kaarten en blocks in je eigen website",
                "description": "<p>Onze Widget-app is een krachtige tool waarmee je moeiteloos kaarten kunt integreren in je eigen website. Met slechts een paar eenvoudige stappen kun je kaarten van verschillende locaties over de hele wereld insluiten, waardoor je website aantrekkelijker en interactiever wordt.</p><p>De Widget-app biedt een intuïtieve gebruikersinterface waarmee iedereen, ook zonder technische kennis, kaarten kunnen insluiten. Voeg je bestaande GeoApps kaarten snel toe in de Widget, en publiceer deze om te plaatsen in de website. Bovendien kun je de stijl van de kaart aanpassen aan de look and feel van je website. </p><p>De Widget-app biedt daarnaast, net als al onze apps, de interactiviteit van de kaart. Je kunt de kaarten interactief maken door markers, labels en zelfs aangepaste pop-ups toe te voegen, en alles werkt direct in de Widget. Hiermee kunnen bezoekers van je website makkelijk je informatie inzien en raadplegen.</p>",
                "images": [
                    "./resources/images/screenshots/widget/widget_1_01.png"
                ],
                "tags": [
                    "Embeds maken"
                ],
            }
        ]
    },
    {
        "id": "46287144-75d7-4542-b800-de3190126ae4",
        "name": "Lijngrafiek",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/line.svg",
                "subtitle": "",
                "description": "<p>Met de Lijngrafiekblock kunt u uw data weergeven in een lijngrafiek. Kies een dataset en pas hier eventueel filters op toe. In dit block heeft u ook de mogelijkheid om een markering met een specifieke waarde in te stellen.</p>",
                "images": [],
                "tags": [
                    "Inzichten",
                    "Grafieken"
                ],
            }
        ]
    },
    {
        "id": "44a4c21e-fd7f-408f-9ca4-11ea65b94043",
        "name": "KiK-Inzage",
        "manifestType": "Extension",
        "items": [
            {
                "icon": "./resources/images/logos/kadaster.svg",
                "subtitle": "",
                "description": "<p>Het Kadaster beheert in Nederland diverse basisregistraties. Een van deze basisregistraties wordt aangeboden onder de naam KIK-Inzage, en biedt de mogelijkheid om inzicht te krijgen in eigendomsgegevens van kadastrale percelen en bijbehorende hypotheekinformatie.</p><p>Met de KIK-inzage in GeoApps kan u via de kaart op een kadastraal perceel klikken en direct een selectie van de eigendomsgegevens bekijken. Daarnaast is een volledig overzicht van de eigendomsgegevens van het perceel als PDF te downloaden. De eigendomsgegevens worden per perceel direct bij het Kadaster in de Basisregistratie Kadaster (BRK) opgevraagd. Hiervoor gebruikt u een eigen Kadaster account, die u in GeoApps instelt.</p>",
                "images": ["./resources/images/screenshots/extension/kik-inzage.jpeg"],
                "tags": [
                    "Open data", 
                    "Productiviteit"
                ],
            }
        ]
    },
    {
        "id": "c5001912-cf4b-486d-a6cc-9ddc2c8ad9f3",
        "name": "Kaart",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/map.svg",
                "subtitle": "",
                "description": "<p>Met het Kaartblock kunt u een eigen geconfigureerde GeoApps kaart tonen in uw app. Kies bijvoorbeeld welke themakaart u wilt en of u de legenda wel of niet wilt tonen.</p>",
                "images": [],
                "tags": [
                    "Kaart"
                ],
            }
        ]
    },
    {
        "id": "531507b8-d20d-4aaa-9ca5-94d7cb5d6ef1",
        "name": "Graadmeter",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/gauge.svg",
                "subtitle": "",
                "description": "<p>Met de Graadmeterblock kunt u data weergeven in de vorm van een meter. Maak verschillende secties aan in uw graadmeter of stel een voor- en/of achtervoegsel in om uw graadmeter helemaal aan te passen naar wens.</p>",
                "images": [],
                "tags": [
                    "Inzichten",
                    "Grafieken"
                ],
            }
        ]
    },
    {
        "id": "8b5c2f7f-74f9-4661-82dd-d2cc9a0eaf16",
        "name": "Google Analytics v4",
        "manifestType": "Extension",
        "items": [
            {
                "icon": "./resources/images/logos/Logo_Google_Analytics.svg",
                "subtitle": "",
                "description": "<p>Krijg een volledig inzicht in de bezoekers van uw kaarten, zoals het aantal bezoekers en van welke apparaten en platforms zij gebruik maken. Google Analytics biedt tools om de statistieken van uw bezoekers beter te begrijpen. </p><p>Gebruik klantgerichte metingen in uw kaarten, zodat u weet hoe uw kaarten gebruikt worden. Bekijk met behulp van geavanceerde rapportage en analyse hoe mensen interactie hebben met uw kaarten. </p>",
                "images": ["./resources/images/screenshots/extension/google-analytics.jpg"],
                "tags": [
                    "Statistieken",
                    "Monitoring"
                ],
            }
        ]
    },
    {
        "id": "5743cfaf-4fa0-4600-b41e-0e4244d3f8cb",
        "name": "Fieldwork (Windows)",
        "manifestType": "Extension",
        "items": [
            {
                "icon": "./resources/images/logos/fieldwork.svg",
                "subtitle": "",
                "description": "<p>Met GeoApps Fieldwork breiden we de tekenmodule van GeoApps uit met de ondersteuning van externe GPS-apparaten. Gebruik deze Fieldwork module in combinatie met de Windows Fieldwork applicatie om via de bluetoothverbinding van uw GPS-apparatuur objecten in te winnen. Het is mogelijk om als veldmedewerker objecten (zoals lijnobjecten of gebieden) in te winnen met hoge GPS-nauwkeurigheid. De ingewonnen objecten worden direct opgeslagen in het vooraf gedefinieerde GeoApps kaartproject. Deze functionaliteit is beschikbaar op aanvraag. Ondersteuning voor diverse merken GPS landmeetapparatuur is in ontwikkeling. Informeer naar de mogelijkheden.<p/>",
                "images": ["./resources/images/screenshots/extension/fieldwork.jpg"],
                "tags": [
                    "GPS",
                    "Data inwinnen"
                ],
            }
        ]
    },
    {
        "id": "3670e9fa-facd-4a59-8f8d-20436b2fbbc7",
        "name": "Donut",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/donut.svg",
                "subtitle": "",
                "description": "<p>Met het Donutblock kunt u uw data weergeven in de vorm van een donut- of, eventueel, taartgrafiek. Kies uw data en pas hier eventueel een filter op toe. Stel bijvoorbeeld een label in of pas de legenda aan.</p>",
                "images": [],
                "tags": [
                    "Inzichten",
                    "Grafieken"
                ],
            }
        ]
    },
    {
        "id": "92a1a86b-685f-4d7f-b1a8-cbbe7db87ea2",
        "name": "Dashboard",
        "manifestType": "App",
        "items": [
            {
                "icon": "./resources/images/dashboard.svg",
                "subtitle": "Breng uw data in beeld op een interactieve en overzichtelijke manier met een Dashboard!",
                "description": "<p> Haal meer inzicht uit uw data. Vertaal data en kaartgegevens automatisch naar een online dashboard. Krijg real-time antwoord op uw vragen. Altijd alles real-time inzichtelijk. Grafieken die in één oogopslag duidelijk maken hoe het ervoor staat. Een dashboard voor elk vraagstuk. Richt dashboards in die aansluiten bij al uw (geo)data-vraagstukken. Dashboards bouwen met meerdere pagina’s. Breid uw dashboard eenvoudig uit met pagina’s per thema of project. Kies uit de vele grafiektypes. Gebruik bijvoorbeeld lijn-, staaf- of taartdiagrammen, en combineer ze met tabellen, kaarten, teksten en afbeeldingen. </p>",
                "images": [
                    "./resources/images/screenshots/dashboards/dashboard_02.png",
                    "./resources/images/screenshots/dashboards/dashboard_03.png",
                    "./resources/images/screenshots/dashboards/dashboard_04.png",
                    "./resources/images/screenshots/dashboards/dashboard_05.png",
                    "./resources/images/screenshots/dashboards/dashboard_06.png",
                    "./resources/images/screenshots/dashboards/dashboard_07.png",
                    "./resources/images/screenshots/dashboards/dashboard_01.png"
                ],
                "tags": [
                    "Interactieve grafieken in een presentatie",
                ],
            }
        ]
    },
    {
        "id": "9d761996-1716-4269-98e2-0458492af13d",
        "name": "Afbeelding",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/image.svg",
                "subtitle": "",
                "description": "<p>Met het Afbeeldingblock kunt u uw eigen afbeeldingen inladen. Kies bijvoorbeeld of u de afbeelding vullend wil maken of juist dat hij zijn originele formaat behoudt. Geef eventueel ook een beschrijving mee aan de afbeelding.</p>",
                "images": [],
                "tags": [
                    "Visueel",
                    "Media",
                    "Grafisch"
                ],
            }
        ]
    },
    {
        "id": "884ce881-dfc5-40ef-999d-8efe59250131",
        "name": "Startpage",
        "manifestType": "App",
        "items": [
            {
                "icon": "./resources/images/startpage.svg",
                "subtitle": "",
                "description": "<p>De Startpage App in GeoApps biedt een overzicht van alle kaarten en Apps die bezoekers kunnen gebruiken. De startpage speelt een centrale rol in het kunnen vinden van kaarten en Apps.</p><p>Op de Startpage is het voor beheerders mogelijk nieuwe Apps te gaan maken. Ook kan dit overzicht gebruikt worden om te starten met het bewerken van de kaarten en Apps.</p>",
                "images": [
                    "./resources/images/screenshots/startpagina/startpagina_01.jpeg",
                    "./resources/images/screenshots/startpagina/startpagina_02.jpeg"
                ],
                "tags": [
                    "Snel inzicht in je projecten"
                ],
            }
        ]
    },
    {
        "id": "e13256c5-a8c6-453a-99a1-84e6200e7693",
        "name": "Blocks combineren",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/stacked.svg",
                "subtitle": "",
                "description": "<p>Met dit block kunt u gemakkelijk andere blocks verticaal of horizontaal combineren.</p>",
                "images": [],
                "tags": [
                    "Lay-out"
                ],
            }
        ]
    },
    {
        "id": "361c99dd-15c1-4d57-b7bf-ac6edccb3116",
        "name": "Knop",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/button.svg",
                "subtitle": "",
                "description": "<p>Met de knop kunt u verschillende acties uitvoeren binnen uw app.</p>",
                "images": [],
                "tags": [
                    "Gebruikers interactie"
                ],
            }
        ]
    },
    {
        "id": "880973d6-9403-4c44-80ff-43e2a78e2fbf",
        "name": "Meerkeuzevraag",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/checkbox.svg",
                "subtitle": "",
                "description": "<p>Met de meerkeuzevraag geeft u uw gebruikers de optie om meerdere keuzes achter te laten.</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "f8822d6f-0e0f-4666-bfee-e016148793d1",
        "name": "Keuzelijst",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/dropdown.svg",
                "subtitle": "",
                "description": "<p>Met de keuzelijst geeft u uw gebruikers meerdere keuze mogelijkheden uit een selectielijst.</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "09a39328-25b0-43ed-9102-b704752bf24c",
        "name": "Titel",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/heading.svg",
                "subtitle": "",
                "description": "<p>Begin een scherm in uw app met een Titel. Meerdere type niveaus zijn beschikbaar.</p>",
                "images": [],
                "tags": [
                    "Lay-out"
                ],
            }
        ]
    },
    {
        "id": "5c6a7bf3-c4c1-4447-bdb2-fbb8e1555ab6",
        "name": "Meerkeuzevraag met afbeeldingen",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/image-checkbox.svg",
                "subtitle": "",
                "description": "<p>Met de meerkeuzevraag met afbeeldingen geeft u uw gebruikers de optie om meerdere keuzes achter te laten.</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "29b8b35e-4705-44a4-8e11-a245fe691102",
        "name": "Keuzevraag",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/radio.svg",
                "subtitle": "",
                "description": "<p>Met de keuzevraag kunnen gebruikers een keuze achterlaten uit een selectie.</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "70ff996e-31bd-4ffd-a73c-fc7592dc87a3",
        "name": "Slider",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/slider.svg",
                "subtitle": "",
                "description": "<p>Met de slider geeft u gebruikers de mogelijkheid om een keuze achter te laten door middel van een slider</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "a3ff58c1-3d66-4b27-baff-fc8875e7bce0",
        "name": "Tekstvak",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/textarea.svg",
                "subtitle": "",
                "description": "<p>Via het tekstvak kunnen gebruikers hun mening achterlaten via een groot tekstvlak.</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "1ec2a9b2-5c3c-49be-b73d-14471bfe9442",
        "name": "Smiley",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/smileys.svg",
                "subtitle": "",
                "description": "<p>Met de smileyblock geeft u gebruikers de mogelijkheid om hun mening achter te laten door middel van boze of blije gezichtjes.</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "1fd5e760-f82e-4d76-b4c3-2d349cf012b0",
        "name": "Tekstveld",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/textinput.svg",
                "subtitle": "",
                "description": "<p>Met het tekstveld geeft u gebruikers de mogelijkheid om hun mening achter te laten via een klein tekstveld.</p>",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "0a1da199-f92a-47c8-9aad-a53736943f9f",
        "name": "Praatmee",
        "manifestType": "App",
        "items": [
            {
                "icon": "./resources/images/praatmee.svg",
                "subtitle": "",
                "description": "",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes"
                ],
            }
        ]
    },
    {
        "id": "bfeb94c5-7b18-4451-83bf-b90d77332c13",
        "name": "Kaartvraag",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/kaartvraag.svg",
                "subtitle": "",
                "description": "Met de kaartvraag kunt u uw gebruikers van uw Praatmee een vraag stellen over een locatie. De gebruiker kan vervolgens een marker plaatsen op de kaart.",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes",
                    "Kaart"
                ],
            }
        ]
    },
    {
        "id": "3470ee3f-77ab-4da9-a001-a89bd774082c",
        "name": "Maak eigen ontwerp",
        "manifestType": "Block",
        "items": [
            {
                "icon": "./resources/images/blocks/tekenmee.svg",
                "subtitle": "",
                "description": "Teken zelf een ontwerp op de kaart. U kunt zelf bepalen welke vormen en welke kleuren u wilt gebruiken.",
                "images": [],
                "tags": [
                    "Participatie",
                    "Enquetes",
                    "Kaart"
                ],
            }
        ]
    },
];