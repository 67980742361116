import { StoreItemDetails } from "./objects/StoreItemDetails";
import { StoreItem } from "./objects/StoreItem";

/**
 * Icons component to display the appropriate icon based on the store item details and type.
 * @param props - The properties object.
 * @returns The JSX element for the icon.
 */
export const Icon = (props: { details: StoreItemDetails; data: StoreItem }) => {
    if (props.details.icon) {
        return <img src={props.details.icon} />;
    } else if (props.data.manifestType === "App") {
        return <img src="./resources/images/startpage.svg" />;
    } else {
        return <img src="./resources/images/blocks/radio.svg" />;
    }
};
