import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { OidcUserStateType } from "../interfaces/OidcUserStateType";
import { NavLink } from "react-router-dom";

export const ProfileMenu = () => {
	const [show, setShow] = useState(false);
	const auth = useAuth();
	const profileRef = useRef<HTMLDivElement>(null);
	const [picture, setPicture] = useState<string>();
	const { t } = useTranslation();

	useEffect(() => {
		// Hide profile menu on click outside of profile menu
		document.addEventListener("click", detectOutsideClick);
		return () => {
			document.removeEventListener("click", detectOutsideClick);
		};
	}, []);

	useEffect(() => {
		if (auth.isAuthenticated && auth.user) {
			getImage();
		}
	}, [auth.isAuthenticated]);

	/**
	 * Detects click outside of profile menu
	 * Hides dropwdown window
	 */
	const detectOutsideClick = (e: MouseEvent) => {
		if (profileRef.current && !profileRef.current?.contains(e.target as Node)) {
			setShow(false);
		}
	};

	/**
	 * Let the user sign in
	 */
	const signIn = () => {
		// Store the active href
		const state: OidcUserStateType = {
			href: window.location.href,
		};

		// Let the user sign in
		auth.signinRedirect({ state: state });
	};
	const signOut = () => {
		auth.signoutRedirect();
	};

	const getTitle = () => {
		const profile = auth.user.profile;
		return `${profile.given_name} ${profile.family_name}`;
	};

	const getImage = () => {
		const profile = auth.user!.profile;

		fetch(profile.picture!, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${auth.user?.access_token}`
			}
		}).then(response => {
			if (response.ok) {
				return response.blob();
			}
		}).then(blob => {
			const url = URL.createObjectURL(blob!);
			setPicture(url);
		});
	}

	if (auth.isAuthenticated && auth.user) {
		const profile = auth.user.profile;
		return (
			<div ref={profileRef} className="profile-menu user">
				<a
					title={getTitle()}
					className="profile-image"
					onClick={() => {
						setShow(!show);
					}}
				>
					<img alt="profile" src={picture} className="profile-picture" />
				</a>
				{show && (
					<div className="currentUserWindow">
						<div className="top">
							<div className="user-info">
								<img className="user-image" src={profile.picture} />
								<div className="user-details">
									<div className="user-name">
										{profile.given_name} {profile.family_name}
									</div>
									<div className="user-email">{profile.email}</div>
									<div className="user-links">
										<button onClick={() => window.open(profile.pwd_url as string)}>
											{t('CHANGE_PASSWORD', { ns: "glossary" })}
										</button>
										<button onClick={() => window.open(profile.tenant_url as string)}>
											{t('MY_ENVIRONMENTS', { ns: "overview" })}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="bottom">
							<NavLink
								className="link"
								to={`/myapps`}
								onClick={() => {
									setShow(!show);
								}}
							>
								Mijn apps
							</NavLink>
							<NavLink
								className="link"
								to={`/updates`}
								onClick={() => {
									setShow(!show);
								}}
							>
								Updates
							</NavLink>
							<a className="link logout" onClick={signOut}>
								{t('LOGOUT', { ns: "common" })}
							</a>
						</div>
					</div>
				)}
			</div>
		);
	}
	return (
		<div className="icon">
			<button className="sign-in" title="Aanmelden" onClick={() => signIn()}>
				<i className="icon icon-user-icon"></i>
			</button>
		</div>
	);
};
