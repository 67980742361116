import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { storeItemData } from "../StoreItemData";
import { StoreItem } from "../objects/StoreItem";
import { ExtendedCardBottom } from "./ExtendedCardBottom";
import { StoreItemDetails } from "../objects/StoreItemDetails";
import { SimpleCardBottom } from "./SimpleCardBottom";
import { Icon } from "../Icons";

export type StoreItemProps = {
	/**
	 * Contains data needed to build up the object (apps, blocks, extensions)
	 */
	data: StoreItem;

	/**
	 * Key
	 */
	key?: string;

	/**
	 * Display the extended version of the card
	 */
	extended?: boolean;
};

export const StoreItemCard = (props: StoreItemProps) => {
	const [details, setDetails] = useState<StoreItemDetails>({
		tags: [],
		icon: "",
	});

	useEffect(() => {
		
		const items = storeItemData.filter(i => i.id === props.data.id)[0]?.items[0];
		if (items) {
			setDetails((prevState) => ({
				...prevState,
				tags: items.tags,
				icon: items.icon,
			}));
		}
	}, [props.data.id]);

	return <>
		<div className="card-top">
			<div className="image">
				<Icon details={details} data={props.data} />
			</div>
			<div className="card-content">
				<h3>{props.data.name}</h3>
				<p>
					{props.extended
						? 
						props.data.description
						: 
						details.tags.join(", ")
					}
				</p>
			</div>
		</div>
		<div className="card-bottom">
			{props.extended
				?
				<ExtendedCardBottom storeItem={props.data} />
				:
				<SimpleCardBottom storeItem={props.data} details={details} />
			}
		</div>
	</>
};
