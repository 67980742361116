import { Trans, useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { EnvironmentContext } from "../EnvironmentContext";
import { useContext, useEffect, useState } from "react";

export const Environments = () => {
	const auth = useAuth();
	const { setEnvironment } = useContext(EnvironmentContext);
	const [invalid, setInvalid] = useState(false);
	const [value, setValue] = useState("");
	const [checkUrl, setCheckUrl] = useState(false);
	const [inputValue, setInputValue] = useState(
		window.location.search.split("=")[1] || sessionStorage.getItem("environment") || ""
	);
	const { t } = useTranslation();

	/**
	 * Check if environment url is valid.
	 */
	useEffect(() => {
		const pattern = /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

		if (!checkUrl) return;

		const searchDelay = setTimeout(() => {
			if (!pattern.test(value)) {
				setInvalid(true);
				setEnvironment?.("");
				return;
			}

			setInvalid(false);
			setEnvironment?.(value);
			sessionStorage.setItem("environment", inputValue);
		}, 300);

		return () => clearTimeout(searchDelay);
	}, [value]);

	// Set environment on init when parameter in the url is given.
	useEffect(() => {
		if (inputValue) 
			setEnvironment?.(inputValue);
			sessionStorage.setItem("environment", inputValue);
	}, [])

	/**
	 * Handle change event 
	 * Set input value after 3 or more characters.
	 * @param e change event
	 */
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const changeValue = e.target.value;
		setInputValue(changeValue);
		setCheckUrl(true);

		if (changeValue.length >= 3) {
			setValue(changeValue);
		} else {
			setInvalid(false);
			setEnvironment?.("");
			setValue("");
			sessionStorage.removeItem("environment");
		}
	};

	return auth.isAuthenticated ? (
		<div className="environments">
			<label htmlFor="environmentInput">{t("ENVIRONMENT")}</label>
			<div className="inputWrap">
				<input
					id="environmentInput"
					onChange={handleChange}
					placeholder= {t("ENVIRONMENT_PLACEHOLDER")}
					aria-invalid={invalid}
					aria-describedby={invalid ? "environmentError" : undefined}
					autoComplete="off"
					value={inputValue}
				/>
				{invalid && (
					<span className="error" id="environmentError" role="alert">
						{t("NO_VALID_URL")}
					</span>
				)}
			</div>
			<div className="infoWrap">
				<a className="info">?</a>
				<div className="info-tooltip">
					<p>
						{t("TOOLTIP_DESCRIPTION")}
					</p>
					<p>
						<Trans i18nKey="TOOLTIP_URL" components={{
								url: <span />
							}}/>
					</p>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};
